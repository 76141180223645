import { format, startOfDay } from 'date-fns';
import { useState } from 'react';

export const DaySelector = ({ onDaySelect, setFilter }) => {
    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const currentYear = new Date().getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedDay, setSelectedDay] = useState(null);

    const daysInMonth = (year, month) =>
        new Array(new Date(year, month + 1, 0).getDate()).fill(null).map((_, index) => index + 1);
    const days = daysInMonth(currentYear, selectedMonth);

    const handleDateChange = (day) => {
        setSelectedDay(day);
        const formattedDate = startOfDay(new Date(currentYear, selectedMonth, day));
        onDaySelect(format(formattedDate, 'yyyy-MM-dd'));
        setFilter('specificDay');
    };

    const handleMonthChange = (monthIndex) => {
        setSelectedMonth(monthIndex);
        setSelectedDay(null); // Resetar o dia ao mudar o mês
    };

    return (
        <div className="flex gap-4">
            <select
                className="border border-black rounded-md cursor-pointer font-medium px-2 py-2"
                value={selectedMonth}
                onChange={(e) => handleMonthChange(parseInt(e.target.value))}
            >
                {months.map((month, index) => (
                    <option key={index} value={index}>{month}</option>
                ))}
            </select>

            <select
                className="border border-black rounded-md cursor-pointer font-medium px-2 py-2"
                value={selectedDay || ''}
                onChange={(e) => handleDateChange(parseInt(e.target.value))}
                disabled={selectedMonth === null}
            >
                <option value="" disabled>Selecione o Dia</option>
                {days.map(day => (
                    <option key={day} value={day + 1}>Dia {day}</option>
                ))}
            </select>
        </div>
    );
};
